import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LocaleService } from '@core/services/locale/locale.service';
import {
  AggregateType,
  ApiResponseRemediationActionEvent,
  ApiResponseRemediationEvent,
  BadgeColor,
  ContentViewDto,
  DataTypeEnum,
  IAction,
  IColumn,
  ITableLoadEvent,
  IssueDto,
  ItemBasePage,
  MessageKeys,
  ModuleKeywordRootPath,
  ModuleKeywords,
  ModuleRoutePrefix,
  OfflineConfig,
  PermissionActions,
  RecordStatusEnumFilterWithoutDeleted,
  TargetTypeEnum,
  humanizeCasedString,
  makePlural,
  parseAggregateData,
  routeToLocaleCase,
  toCamelCase,
  toKebabCase,
} from '@shared/classes';
import { Remediation } from '@shared/classes/model/backend/issue';
import { BaseFormPopupComponent } from '@shared/components/misc/base-form-popup/base-form-popup.component';
import { UserSelectorComponent } from '@shared/components/selectors/user-selector/user-selector.component';
import { AppDialogService } from '@shared/services';
import { FormMapperService } from '@shared/services/mappings/form-mapper.service';
import { RemediationActionMappingService } from '@shared/services/mappings/issue/remediation-action-mapping.service';
import { ToastService } from '@shared/services/toast.service';
import { ViewModeService } from '@shared/services/view-mode.service';
import { AppInjector } from 'app/app-injector';
import { combineLatest, takeUntil } from 'rxjs';
import { IssuesDataService } from '../../services/data/issues-data.service';
import { RemediationActionDataService } from '../../services/data/remediation-action-data.service';
import { RemediationPlanDataService } from '../../services/data/remediation-plan-data.service';
import { RemediationChangeStatusFormComponent } from '../remediation-change-status-form/remediation-change-status-form.component';

@Component({
  selector: 'app-remediation-plan-item',
  templateUrl: './remediation-plan-item.component.html',
  styleUrls: ['./remediation-plan-item.component.scss'],
})
export class RemediationPlanItemComponent extends ItemBasePage<Remediation> {
  badgeColors = BadgeColor;
  chartData: any = {
    [IssueDto.IssueStatusEnum.Analysis]: 0,
    [IssueDto.IssueStatusEnum.Draft]: 0,
    [IssueDto.IssueStatusEnum.ClosedResolved]: 0,
    [IssueDto.IssueStatusEnum.Remediation]: 0,
    [IssueDto.IssueStatusEnum.ClosedUnresolved]: 0,
  };

  cdata: any;

  options: any;

  toDraftAction: IAction = {
    id: 1,
    label: 'Return to Draft',
    buttonType: 'button',
    color: 'secondary',
    command: this.onChangeStatus.bind(this, ApiResponseRemediationEvent.DataEnum.Draft),
    icon: 'fas fa-clock-rotate-left',
  };

  toSubmittedAction: IAction = {
    id: 2,
    label: 'Submit',
    buttonType: 'button',
    color: 'info',
    command: this.onChangeStatus.bind(this, ApiResponseRemediationEvent.DataEnum.Submitted),
    icon: 'fas fa-user-clock',
  };
  toApprovedAction: IAction = {
    id: 3,
    label: 'Approved',
    buttonType: 'button',
    command: this.onChangeStatus.bind(this, ApiResponseRemediationEvent.DataEnum.Approved),
    icon: 'fas fa-check-double',
    color: 'success',
  };

  approveAction: IAction = {
    id: 5,
    label: 'Approve',
    buttonType: 'button',
    command: this.onApproveEvent.bind(this, 'APPROVE'),
    icon: 'pi pi-check',
    color: 'success',
  };
  rejectAction: IAction = {
    id: 6,
    label: 'Reject',
    buttonType: 'button',
    command: this.onApproveEvent.bind(this, 'REJECT'),
    icon: 'pi pi-times',
    color: 'success',
  };

  toCompletedAction: IAction = {
    id: 4,
    label: 'Completed',
    buttonType: 'button',
    command: this.onChangeStatus.bind(this, ApiResponseRemediationEvent.DataEnum.Completed),
    icon: 'pi pi-check',
    color: 'danger',
  };
  toStartedAction: IAction = {
    id: 5,
    label: 'Start',
    buttonType: 'button',
    color: 'warning',
    command: this.onChangeStatus.bind(this, ApiResponseRemediationEvent.DataEnum.Started),
    icon: 'pi pi-send',
  };
  get actionsMap() {
    return {
      // [ApiResponseRemediationEvent.DataEnum.Approved]: this.toApprovedAction,
      ['APPROVE']: this.approveAction,
      ['REJECT']: this.rejectAction,
      [ApiResponseRemediationEvent.DataEnum.Approved]: this.toApprovedAction,
      ['TO_REJECTED']: this.rejectAction,
      [ApiResponseRemediationEvent.DataEnum.Completed]: this.toCompletedAction,
      [ApiResponseRemediationEvent.DataEnum.Draft]: this.toDraftAction,
      [ApiResponseRemediationEvent.DataEnum.Started]: this.toStartedAction,
      [ApiResponseRemediationEvent.DataEnum.Submitted]: this.toSubmittedAction,
    };
  }
  get statusChangeValue() {
    return {
      [ApiResponseRemediationEvent.DataEnum.Approved]: Remediation.StatusEnum.Approved,
      [ApiResponseRemediationEvent.DataEnum.Completed]: Remediation.StatusEnum.Completed,
      [ApiResponseRemediationEvent.DataEnum.Draft]: Remediation.StatusEnum.Draft,
      [ApiResponseRemediationEvent.DataEnum.Started]: Remediation.StatusEnum.Started,
      [ApiResponseRemediationEvent.DataEnum.Submitted]: Remediation.StatusEnum.Submitted,
    };
  }
  onChangeStatus(status: ApiResponseRemediationEvent.DataEnum) {
    if (status == 'TO_COMPLETED') {
      const popupFormData = {};
      this.data?.issues?.forEach((issue) => {
        popupFormData[issue] = IssueDto.IssueStatusEnum.ClosedResolved;
      });
      this.appDialogService.showDialog(
        BaseFormPopupComponent,
        'Change Status',
        (data) => {
          if (data) {
            this.changeStatusRequest(status, { messages: data });
          }
        },
        {
          width: '50%',
          data: {
            dynamicViewComponent: RemediationChangeStatusFormComponent,
            dataService: this.requestService,
            filters: [],
            selectedRows: [],
            patchData: false,
            formData: { CLOSE_ISSUES: popupFormData },
          },
        }
      );
    } else {
      this.changeStatusRequest(status);
    }
  }
  changeStatusRequest(status: ApiResponseRemediationEvent.DataEnum, body = {}) {
    this.requestService.changeStatus(this.itemId, status, body).subscribe((red) => {
      // this.requestService.navigateToListPage();
      this.data.status = this.statusChangeValue[status];
      this.getItemData(this.itemId);
      this.refreshRules();
    });
  }
  cols: IColumn[] = [];
  offlineConfig: OfflineConfig = {
    lazy: true,
    paginator: true,
    showActionBar: false,
  };
  statusTotal: number = 0;
  linkedIssues: IssueDto[] = [];
  linkedIssuesTableEvent: ITableLoadEvent = new ITableLoadEvent();
  remediationActionTableCardContent: ContentViewDto;
  showRemediationActionTable = true;
  remediationActionPageActions: IAction[] = [
    {
      label: 'Add Item',
      id: 1,
      color: 'primary',
      icon: 'pi pi-plus',
      iconPos: 'left',
      command: this.addRemediationActionPopup.bind(this),
      permission: `${PermissionActions.Create}${ModuleKeywords.RemediationAction}`,
    },
    {
      label: 'Set To Do',
      id: 1,
      color: 'primary',
      icon: 'pi pi-plus',
      iconPos: 'left',
      command: this.bulkToDoAction.bind(this),
      permission: `${PermissionActions.Update}${ModuleKeywords.RemediationAction}`,
      displayCommand: (data) => (this.selectedRows?.length ? true : false),
    },
  ];
  remediationActionTableActions: IAction[] = [
    {
      label: '',
      id: 1,
      color: 'secondary',
      icon: 'pi pi-plus',
      iconPos: 'left',
      command: this.addRemediationActionPopup.bind(this),
      permission: `${PermissionActions.Create}${ModuleKeywords.RemediationAction}`,
      tooltipText: 'Add Child',
      tooltipOptions: {
        tooltipLabel: 'Add Child',
      },
    },
    {
      label: '',
      id: 2,
      color: 'secondary',
      icon: 'pi pi-user',
      iconPos: 'left',
      command: this.onChangeStatusRemediationAction.bind(this, ApiResponseRemediationActionEvent.DataEnum.Todo),
      permission: `${PermissionActions.Update}${ModuleKeywords.RemediationAction}`,
      tooltipText: 'Set To Do',
      tooltipOptions: {
        tooltipLabel: 'Set To Do',
      },
    },
  ];
  constructor(
    public requestService: RemediationPlanDataService,
    public issuesRequestService: IssuesDataService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    public viewModeService: ViewModeService,
    private appDialogService: AppDialogService,
    public localeService: LocaleService,
    private remediationActionMapping: RemediationActionMappingService,
    private remediationActionRequest: RemediationActionDataService,
    private formMapper: FormMapperService
  ) {
    super(
      {
        moduleKeyword: ModuleKeywords.Remediation,
        routePrefix: ModuleRoutePrefix.IssueManagement,
      },
      router,
      requestService,
      toastService
    );

    combineLatest([this.activatedRoute.paramMap])
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.itemId = data[0].get('id');
        this.editMode = !!this.itemId;
        this.SetPageValues({
          breadCrumb: {
            items: [
              {
                label: this.localeService.translate(
                  `modules.${routeToLocaleCase(this.ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(ModuleKeywordRootPath[this.moduleKeyword])}`
                ),
                routerLink: [`${this.routePrefix ? this.routePrefix : toKebabCase(this.moduleKeyword)}`],
              },
              {
                label: this.localeService.translate(
                  `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(this.moduleKeyword)}.${makePlural(toCamelCase(this.moduleKeyword))}`
                ),
                routerLink: [`/${this.routePrefix}${toKebabCase(this.moduleKeyword)}/list`],
              },
              {
                label: this.itemId
                  ? this.itemId
                  : this.localeService.translate('general.actions.add') +
                    ' ' +
                    this.localeService.translate(
                      `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(this.moduleKeyword)}.${toCamelCase(this.moduleKeyword)}`
                    ),
                routerLink: [],
              },
            ],
          },
          itemId: this.itemId,
          fromType: TargetTypeEnum.Remediation,
        });
        this.setRemediationActionsTableCardObj();
      });
    this.initIssueCols();
  }
  // cardContent: ViewCardItem;
  setRemediationActionsTableCardObj() {
    const tableModule: ModuleKeywords = ModuleKeywords.RemediationAction;
    this.remediationActionTableCardContent = {
      // ...this.cardContent?.contentViewObject,
      name: this.localeService.translate(
        `modules.${routeToLocaleCase(ModuleKeywordRootPath[tableModule])}.${toCamelCase(tableModule)}.${makePlural(toCamelCase(tableModule))}`
      ),
      targetType: tableModule as any,
      tableViewConfig: {
        // ...this.cardContent?.contentViewObject?.drillDownTableViewConfig,
        columns: [...this.remediationActionMapping.tableFields.filter((x) => x.key != 'remediation')],
        showActions: true, //change to show actions
        showGlobalFilter: true,
        showOperationActions: true,
        showDeleteAction: false,
        showEditAction: false,
        showLockAction: false,
        showActivateAction: false,
        showDeactivateAction: false,
        showStatusActions: true,
        showSettingsLink: false,
        showFileExportAction: false,
        showPackageExportAction: false,
        showBulkOperationAction: true,
      },
      filterView: {
        // ...this.cardContent?.contentViewObject?.filterView,
        targetType: tableModule as any,
        filter: {
          // ...this.cardContent?.contentViewObject?.filterView.filter,
          filters: [{ property: 'remediation', operation: 'EQUAL', value: this.itemId }],
        },
      },
    };
  }
  onSetData(): void {
    this.fetchIssues(null);
    this.subs.sink = this.issuesRequestService
      .aggregate({
        aggregationFields: [
          {
            operation: 'COUNT',
            fieldName: 'issueStatus',
          },
        ],
        groupByFields: [{ fieldName: 'issueStatus', typeShape: 'NORMAL' }],
        filters: [{ property: 'code', operation: 'IN', value: this.data?.issues }],
      })
      .subscribe((res) => {
        this.statusTotal = 0;
        // this.chartData = {
        //     [IssueDto.IssueStatusEnum.Analysis]: 0,
        //     [IssueDto.IssueStatusEnum.Draft]: 0,
        //     [IssueDto.IssueStatusEnum.ClosedResolved]: 0,
        //     [IssueDto.IssueStatusEnum.Remediation]: 0,
        //     [IssueDto.IssueStatusEnum.ClosedUnresolved]: 0
        // };
        // this.linkedIssues?.forEach((el) => {
        //     this.chartData[el.issueStatus]++;
        // });
        this.chartData = parseAggregateData(
          res.aggregation_value,
          'issueStatus',
          AggregateType.Count,
          IssueDto.IssueStatusEnum
        );
        Object.keys(this.chartData).forEach((element) => {
          this.statusTotal += this.chartData[element];
        });

        this.addChartData();
      });
  }

  fetchIssues(tableEvent) {
    this.subs.sink = this.issuesRequestService
      .search(
        { ...this.linkedIssuesTableEvent.pageInfo.pagination },
        { filters: [{ property: 'code', operation: 'IN', value: this.data?.issues }] },
        { showLoading: false, showMsg: false }
      )
      .subscribe((res) => {
        this.linkedIssues = res.content as any[];
      });
  }

  addChartData() {
    const documentStyle = getComputedStyle(document.documentElement);

    const textColor = documentStyle.getPropertyValue('--text-color');

    this.cdata = {
      labels: Object.keys(this.chartData).map((x) => humanizeCasedString(x)), //["Draft", "Analysis", "Closed Resolved", "Closed Un resolved", "Remediation"],
      datasets: [
        {
          data: Object.keys(this.chartData).map((x) => this.chartData[x]),
          backgroundColor: Object.keys(this.chartData).map((x) => this.badgeColors[x]),
          hoverBackgroundColor: Object.keys(this.chartData).map((x) => this.badgeColors[x]),
        },
      ],
    };

    this.options = {
      borderColor: 'transparent',
      cutout: '60%',
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
      },
    };
  }

  initIssueCols() {
    this.cols = [
      {
        name: 'Code',
        key: 'code',
        dataType: DataTypeEnum.CodeWithStatus,
        passRowAsData: true,
        filter: {
          type: 'text',
          matchMode: 'startsWith',
        },
        // frozen: true,
        alignFrozen: 'left',
      },
      {
        name: 'Record Status',
        key: 'recordStatus',
        dataType: DataTypeEnum.Badge,
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: RecordStatusEnumFilterWithoutDeleted,
        },
      },
      {
        name: 'Name',
        key: 'name',
        dataType: DataTypeEnum.Text,
        filter: {
          type: 'text',
          matchMode: 'startsWith',
        },
      },
      {
        name: 'Criticality',
        key: 'criticality',
        dataType: DataTypeEnum.Badge,
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: IssueDto.CriticalityEnum,
        },
      },
      {
        name: 'Owner',
        key: 'issueOwner',
        dataType: DataTypeEnum.ResponsibilityListView,
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.Responsibility,
        },
      },
      {
        name: 'Status',
        key: 'issueStatus',
        dataType: DataTypeEnum.Badge,
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: IssueDto.IssueStatusEnum,
        },
      },
      {
        name: 'Assignee ',
        key: 'assignee',
        dataType: DataTypeEnum.ResponsibilityListView,
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.Responsibility,
        },
      },
      {
        name: 'Creator Name',
        key: 'creatorName',
        dataType: DataTypeEnum.UserListView,
        filter: {
          type: 'multiDropdown',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          dynamicInput: {
            componentType: UserSelectorComponent,
            options: {
              label: '',
              name: '',
              control: new FormControl(null),
            },
          },
        },
      },
    ];
  }

  onApproveEvent(status: 'APPROVE' | 'REJECT') {
    this.requestService.getApprove(this.data?.id, status).subscribe((red) => {
      // this.requestService.navigateToListPage();
      this.getItemData(this.itemId);
      this.refreshRules();
    });
  }
  addRemediationActionPopup(row?: any) {
    this.appDialogService.showDialog(
      BaseFormPopupComponent,
      this.localeService.translate('general.actions.add') +
        ' ' +
        this.localeService.translate(
          `modules.${routeToLocaleCase(ModuleKeywordRootPath[ModuleKeywords.RemediationAction])}.${toCamelCase(ModuleKeywords.RemediationAction)}.${toCamelCase(ModuleKeywords.RemediationAction)}`
        ),
      (data) => {
        if (data) {
          if (!(data as any[])?.find((x) => x.key == 'remediation')) {
            data.push({ key: 'remediation', value: this.itemId });
          }
          let resObj: any = {};
          data.forEach((element) => {
            resObj[element.key] = element.value;
          });
          this.subs.sink = this.remediationActionRequest.create({ createItems: data }).subscribe((res) => {
            // this.data.auditRequests = [...this.data.auditRequests, res.data];
            this.showRemediationActionTable = false;
            setTimeout(() => {
              this.showRemediationActionTable = true;
            }, 50);
          });
        }
      },
      {
        data: {
          dynamicViewComponent: this.formMapper.forms.REMEDIATION_ACTION,
          // dataService: this.remediationActionRequest,
          filters: [],
          selectedRows: [],
          patchData: false,
          formData: { remediation: this.itemId, parent: row?.code ? row?.code : null },
        },
      }
    );
  }
  onChangeStatusRemediationAction(status: ApiResponseRemediationActionEvent.DataEnum) {
    this.remediationActionRequest.changeStatus(this.itemId, status).subscribe((red) => {
      // this.requestService.navigateToListPage();
      // this.getItemData(this.itemId);
      // this.refreshRules();
      this.showRemediationActionTable = false;
      setTimeout(() => {
        this.showRemediationActionTable = true;
      }, 5);
    });
  }
  bulkToDoAction() {
    this.remediationActionRequest
      .bulkChangeStatus({
        codes: this.selectedRows?.map((x) => x?.code),
        toStatus: ApiResponseRemediationActionEvent.DataEnum.Todo,
      })
      .subscribe((res) => {
        const toastService = AppInjector.get(ToastService);
        toastService.success(MessageKeys.success, MessageKeys.editedSuccessfully);
        this.showRemediationActionTable = false;
        setTimeout(() => {
          this.showRemediationActionTable = true;
        }, 5);
      });
  }
  selectedRows = [];
  onRowSelection(event) {
    this.selectedRows = [...event];
  }
}
